import $api from '@/api'
import * as notify from '@/utils/notify'

export const fetchAllParticipants = async payload => {
  let result = {}

  try {
    result = await $api.assignments.fetchAllParticipants(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const fetchDetailWithAssignee = async ({ assignmentId, userId }) => {
  let result = {}

  try {
    result = await $api.assignments.fetchDetailWithAssignee({
      assignmentId,
      userId
    })
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const fetchDashboardImage = async ({ distributedTeamAssignmentId }) => {
  let result = {}

  try {
    result = await $api.assignments.fetchDashboardImage({
      distributedTeamAssignmentId
    })
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const fetchAllInvitees = async ({ assignmentId }) => {
  let result = {}

  try {
    result = await $api.assignments.fetchAllInvitees({
      assignmentId
    })
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const fetchAllWithBudgetsAndPayoutsByUserId = async payload => {
  let result = {}

  try {
    result =
      await $api.assignments.fetchAllWithBudgetsAndPayoutsByUserId(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const updateOneSingleProperty = async payload => {
  let result = {}

  try {
    result = await $api.assignments.updateOneSingleProperty(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const inviteUsersOrTeams = async payload => {
  let result = {}

  try {
    result = await $api.assignments.inviteUsersOrTeams(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}
