import * as services from '@/services'

const defaultFilters = Object.freeze({
  orderBy: 'updatedAt',
  isSortAscending: false
})

const applySorting = (list, filters) => {
  const { orderBy, isSortAscending } = filters

  let sortedList = structuredClone(list) || []

  if (orderBy === 'name') {
    sortedList.sort((a, b) => {
      return isSortAscending
        ? a.payeeUser.name.localeCompare(b.payeeUser.name)
        : b.payeeUser.name.localeCompare(a.payeeUser.name)
    })
  }

  if (orderBy === 'function') {
    sortedList.sort((a, b) => {
      return isSortAscending
        ? a.workFunction.name.localeCompare(b.workFunction.name)
        : b.workFunction.name.localeCompare(a.workFunction.name)
    })
  }

  if (orderBy === 'updatedAt') {
    sortedList.sort((a, b) =>
      isSortAscending
        ? new Date(a.updatedAt || a.createdAt) -
          new Date(b.updatedAt || b.createdAt)
        : new Date(b.updatedAt || b.createdAt) -
          new Date(a.updatedAt || a.createdAt)
    )
  }

  if (orderBy === 'assigned') {
    sortedList.sort((a, b) =>
      isSortAscending
        ? a.assignedPaymentAmount - b.assignedPaymentAmount
        : b.assignedPaymentAmount - a.assignedPaymentAmount
    )
  }
  if (orderBy === 'revisions') {
    sortedList.sort((a, b) =>
      isSortAscending
        ? a.revisionsPaymentAmount - b.revisionsPaymentAmount
        : b.revisionsPaymentAmount - a.revisionsPaymentAmount
    )
  }
  if (orderBy === 'finalist') {
    sortedList.sort((a, b) =>
      isSortAscending
        ? a.finalistPaymentAmount - b.finalistPaymentAmount
        : b.finalistPaymentAmount - a.finalistPaymentAmount
    )
  }
  if (orderBy === 'winner') {
    sortedList.sort((a, b) =>
      isSortAscending
        ? a.winnerPaymentAmount - b.winnerPaymentAmount
        : b.winnerPaymentAmount - a.winnerPaymentAmount
    )
  }
  if (orderBy === 'total') {
    sortedList.sort((a, b) =>
      isSortAscending
        ? a.totalPaymentAmount - b.totalPaymentAmount
        : b.totalPaymentAmount - a.totalPaymentAmount
    )
  }

  return sortedList
}

export default {
  namespaced: true,

  state: {
    budget: {
      assignmentId: null,
      content: null,

      isLoading: false,

      rows: {
        list: []
      },

      filters: structuredClone(defaultFilters),

      ui: {
        createBudgetDialog: { isVisible: false },
        createPayoutDialog: { isVisible: false, payout: null }
      }
    }
  },

  actions: {
    async FETCH_ALL({ commit, state }) {
      const filters = state.budget.filters

      await Promise.all([
        commit('SET_IS_LOADING', true),
        commit('SET_CONTENT', null),
        commit('SET_ROWS_LIST', [])
      ])

      const { list } = await services.assignmentBudgets.fetchAllByAssignmentId({
        assignmentId: state.budget.assignmentId
      })

      const budget = list[0]

      let payoutsResult = {}

      if (budget) {
        payoutsResult =
          await services.assignmentPayouts.fetchAllByAssignmentBudgetId({
            assignmentBudgetId: budget.id
          })
      }

      const payoutsResultList = applySorting(payoutsResult.list || [], filters)

      await Promise.all([
        commit('SET_CONTENT', budget || null),
        commit('SET_ROWS_LIST', payoutsResultList || []),
        commit('SET_IS_LOADING', false)
      ])
    },

    async CREATE_BUDGET({ commit, state, dispatch }) {
      await commit('SET_IS_LOADING', true)

      const { content, isSuccess } = await services.assignmentBudgets.createOne(
        { assignmentId: state.budget.assignmentId }
      )

      await Promise.all([
        commit('SET_IS_LOADING', false),
        commit('SET_CONTENT', content)
      ])

      await dispatch('FETCH_ALL')

      return { isSuccess }
    },

    async APPLY_FILTERS({ commit, state }) {
      const filters = state.budget.filters

      let list = state.budget.rows.list

      list = applySorting(list, filters)

      await Promise.all([commit('SET_ROWS_LIST', list)])
    }
  },

  mutations: {
    SET_ASSIGNMENT_ID(state, assignmentId) {
      state.budget.assignmentId = assignmentId
    },
    SET_CONTENT(state, content) {
      state.budget.content = content
    },

    SET_IS_LOADING(state, isLoading) {
      state.budget.isLoading = isLoading
    },

    SET_ROWS_LIST(state, list) {
      state.budget.rows.list = list
    },

    // UPDATE_FILTERS(state, toUpdate) {
    //   state.budget.filters = { ...state.budget.filters, ...toUpdate }
    // },
    // SET_FILTERS(state, filters) {
    //   state.budget.filters = filters
    // },
    // RESET_FILTERS(state) {
    //   state.budget.filters = structuredClone(defaultFilters)
    //   state.budget.defaultFilters = structuredClone(defaultFilters)
    // },

    // SET_DEFAULT_FILTERS(state, defaultFilters) {
    //   state.budget.defaultFilters = structuredClone(defaultFilters)
    // },

    SET_FILTERS_ORDER_BY(state, orderBy) {
      state.budget.filters.orderBy = orderBy
    },
    SET_FILTERS_IS_SORT_ASCENDING(state, isSortAscending) {
      state.budget.filters.isSortAscending = isSortAscending
    },

    UPDATE_UI(state, toUpdate) {
      state.budget.ui = { ...state.budget.ui, ...toUpdate }
    }
  }
}
