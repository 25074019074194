<template>
  <div class="gap-4 flex flex-col">
    <h1 class="text-2xl font-black">Member Profile</h1>

    <div
      class="gap-4 flex flex-col"
      :class="{
        'md:flex-row': $isOn.principalPage
      }"
    >
      <div>
        <v-card class="sticky top-20 flex flex-initial flex-shrink-0">
          <BaseProfilePrincipalTabs
            :value="activeTabCode"
            :tabs="tabs"
            :is-vertical="$isOn.adminPage"
            @select="handleSelectTab"
          />
        </v-card>
      </div>

      <div class="grid grid-cols-12 gap-4 flex flex-col">
        <div class="col-span-12">
          <component
            :is="activeTab.component"
            :user="user"
            v-bind="activeTab.props"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import { generateConnection } from '@/utils/base'

import BaseProfilePrincipalTabs from '@/components/Base/BaseProfilePrincipalTabs.vue'

const TabsPersonalInfo = () =>
  import('@/components/Tenant/Member/MemberProfile/Tabs/TabsPersonalInfo.vue')
const TabsHealthcare = () =>
  import('@/components/Tenant/Member/MemberProfile/Tabs/TabsHealthcare.vue')
const TabsExpertise = () =>
  import('@/components/Tenant/Member/MemberProfile/Tabs/TabsExpertise.vue')
const TabsAssessments = () =>
  import('@/components/Tenant/Member/MemberProfile/Tabs/TabsAssessments.vue')
const TabsAvailability = () =>
  import('@/components/Tenant/Member/MemberProfile/Tabs/TabsAvailability.vue')
const TabsLinks = () =>
  import('@/components/Tenant/Member/MemberProfile/Tabs/TabsLinks.vue')
const TabsFiles = () =>
  import('@/components/Tenant/Member/MemberProfile/Tabs/TabsFiles.vue')
const TabsRates = () =>
  import('@/components/Tenant/Member/MemberProfile/Tabs/TabsRates.vue')
// const TabsComments = () =>
//   import('@/components/Tenant/Member/MemberProfile/Tabs/TabsComments.vue')
const TabsContracts = () =>
  import('@/components/Tenant/Member/MemberProfile/Tabs/TabsContracts.vue')
// const TabsCredentials = () =>
//   import('@/components/Tenant/Member/MemberProfile/Tabs/TabsCredentials.vue')
const TabsInvoices = () =>
  import('@/components/Tenant/Member/MemberProfile/Tabs/TabsInvoices.vue')
const TabsPayouts = () =>
  import('@/components/Tenant/Member/MemberProfile/Tabs/TabsPayouts.vue')
const TabsPartners = () =>
  import('@/components/Tenant/Member/MemberProfile/Tabs/TabsPartners.vue')

const TABS = (() => {
  const CODES = Object.freeze({
    PERSON_INFO: 'person-info',
    HEALTHCARE: 'healthcare',
    EXPERTISE: 'expertise',
    ASSESSMENTS: 'assessments',
    AVAILABILITY: 'availability',
    PORTFOLIO_LINKS: 'portfolio-links',
    PORTFOLIO_FILES: 'portfolio-files',
    RATES: 'rates',
    COMMENTS: 'comments',
    CONTRACTS: 'contracts',
    // CREDENTIALS: 'credentials',
    INVOICES: 'invoices',
    PAYOUTS: 'payouts',
    PARTNERS: 'partners'
  })

  const LABELS = Object.freeze({
    PERSON_INFO: 'Personal Info',
    HEALTHCARE: 'Healthcare Experience',
    EXPERTISE: 'Expertise',
    ASSESSMENTS: 'Assessments',
    AVAILABILITY: 'Availability',
    PORTFOLIO_LINKS: 'Personal Links',
    PORTFOLIO_FILES: 'Personal Files',
    RATES: 'Rates',
    CONTRACTS: 'Contracts',
    COMMENTS: 'Comments',
    // CREDENTIALS: 'Credentials',
    INVOICES: 'Invoices',
    PAYOUTS: 'Payouts',
    PARTNERS: 'Work Partners'
  })

  return Object.freeze({
    codes: CODES,
    labels: LABELS,

    labelByCode: generateConnection(LABELS, CODES)
  })
})()

const BASE_TAB_CODE = TABS.codes.PERSON_INFO

export { TABS }
export default {
  name: 'TenantMemberProfile',

  components: {
    BaseProfilePrincipalTabs,

    TabsPersonalInfo,
    TabsHealthcare,
    TabsExpertise,
    TabsAssessments,
    TabsAvailability,
    TabsLinks,
    TabsFiles,
    TabsRates,
    // TabsComments,
    TabsContracts,
    // TabsCredentials,
    TabsInvoices,
    TabsPayouts,
    TabsPartners
  },

  props: {
    user: { type: Object, required: true }
  },

  data: () => ({
    activeTabCode: BASE_TAB_CODE
  }),

  computed: {
    ...mapState('memberNotifications', {
      notifs: 'notifications'
    }),

    activeTab() {
      return (
        this.tabs.find(tab => tab.code === this.activeTabCode) || this.tabs[0]
      )
    },

    tabs() {
      return [
        {
          code: TABS.codes.PERSON_INFO,
          text: TABS.labels.PERSON_INFO,
          icon: 'mdi-account-circle-outline',
          component: 'TabsPersonalInfo'
        },
        {
          code: TABS.codes.HEALTHCARE,
          text: TABS.labels.HEALTHCARE,
          icon: 'mdi-heart-plus-outline',
          component: 'TabsHealthcare'
        },
        {
          code: TABS.codes.EXPERTISE,
          text: TABS.labels.EXPERTISE,
          icon: 'mdi-math-compass',
          component: 'TabsExpertise'
        },
        {
          code: TABS.codes.ASSESSMENTS,
          text: TABS.labels.ASSESSMENTS,
          icon: 'mdi-school-outline',
          component: 'TabsAssessments',
          badge: this.notifs.tasks.availableCount
        },
        {
          code: TABS.codes.AVAILABILITY,
          text: TABS.labels.AVAILABILITY,
          icon: 'mdi-calendar-month-outline',
          component: 'TabsAvailability'
        },

        {
          code: TABS.codes.PORTFOLIO_FILES,
          text: TABS.labels.PORTFOLIO_FILES,
          icon: 'mdi-file-account',
          component: 'TabsFiles',
          props: { headerTitle: 'Personal Files' }
        },
        {
          code: TABS.codes.PORTFOLIO_LINKS,
          text: TABS.labels.PORTFOLIO_LINKS,
          icon: 'mdi-file-link-outline',
          component: 'TabsLinks',
          props: { headerTitle: 'Personal Links' }
        },

        {
          code: TABS.codes.RATES,
          text: TABS.labels.RATES,
          icon: 'mdi-cash',
          component: 'TabsRates'
        },
        // {
        //   code: TABS.codes.COMMENTS,
        //   text: TABS.labels.COMMENTS,
        //   icon: 'mdi-comment-multiple-outline',
        //   component: 'TabsComments'
        // },
        {
          code: TABS.codes.CONTRACTS,
          text: TABS.labels.CONTRACTS,
          icon: 'mdi-script-outline',
          component: 'TabsContracts'
        },
        // {
        //   code: TABS.codes.CREDENTIALS,
        //   text: TABS.labels.CREDENTIALS,
        //   icon: 'mdi-certificate-outline',
        //   component: 'TabsCredentials'
        // },
        this.user?.canUploadInvoices && {
          code: TABS.codes.INVOICES,
          text: TABS.labels.INVOICES,
          icon: 'mdi-receipt',
          component: 'TabsInvoices'
        },
        {
          code: TABS.codes.PAYOUTS,
          text: TABS.labels.PAYOUTS,
          icon: 'mdi-cash-multiple',
          component: 'TabsPayouts'
        },
        {
          code: TABS.codes.PARTNERS,
          text: TABS.labels.PARTNERS,
          icon: 'mdi-account-multiple',
          component: 'TabsPartners'
        }
      ].filter(Boolean)
    }
  },

  watch: {
    '$route.params.tabCode': {
      immediate: true,
      handler(value, oldValue) {
        if (value && value === oldValue) return

        this.handleChangeRouteTabCode({ isInitial: !value })
      }
    }
  },

  methods: {
    handleChangeRouteTabCode({ isInitial = false }) {
      const code = this.$route.params.tabCode
      const isValid = this.defineIsValidCode(code)

      if (isValid) {
        this.activeTabCode = code
      } else {
        this.activeTabCode = BASE_TAB_CODE
        this.handleSelectTab({ code: this.activeTabCode, isInitial })
      }

      this.scrollToTop()
    },

    handleSelectTab({ code, isInitial }) {
      const params = { ...this.$route.params, tabCode: code }

      const options = { name: this.$route.name, params }

      isInitial ? this.$router.replace(options) : this.$router.push(options)
    },

    defineIsValidCode(code) {
      if (!code) return

      const codes = this.tabs.map(tab => tab.code)
      const isValid = codes.includes(code)

      return isValid
    },

    scrollToTop() {
      window.scrollTo(0, 0)
    }
  }
}
</script>
