import $api from '@/api'
import * as notify from '@/utils/notify'

export const fetchAllById = async payload => {
  let result = {}

  try {
    result = await $api.assignmentBudgets.fetchAllById(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const fetchAllByAssignmentId = async payload => {
  let result = {}

  try {
    result = await $api.assignmentBudgets.fetchAllByAssignmentId(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const exportOne = async payload => {
  let result = {}

  try {
    result = await $api.assignmentBudgets.exportOne(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const createOne = async payload => {
  let result = {}

  try {
    result = await $api.assignmentBudgets.createOne(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const updateOne = async payload => {
  let result = {}

  try {
    result = await $api.assignmentBudgets.updateOne(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const deleteOne = async payload => {
  let result = {}

  try {
    result = await $api.assignmentBudgets.deleteOne(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}
