import axios from 'axios'

import { formatItemResponse, formatListResponse } from '@/utils/api'

const BASE_URL = `/api/user-relationship`
const URLs = new Proxy(
  {
    FETCH_ALL: `/get-all`,
    FETCH_ALL_BY_ID: `/get-by-id/`,
    FETCH_ALL_BY_USER_ID: `/get-by-user-id/`,

    CREATE_ONE: '/create',
    UPDATE_ONE: '/update',
    DELETE_ONE: '/delete/'
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchAll() {
    const res = await axios.get(URLs.FETCH_ALL)

    return formatListResponse(res)
  },
  async fetchAllById({ id }) {
    const res = await axios.get(`${URLs.FETCH_ALL_BY_ID}${id}`)

    return formatListResponse(res)
  },
  async fetchAllByUserId({ userId, userRelationshipTypeCode }) {
    const res = await axios.get(
      `${URLs.FETCH_ALL_BY_USER_ID}${userId}/${userRelationshipTypeCode}`
    )

    return formatListResponse(res)
  },

  async createOne(payload) {
    const res = await axios.post(URLs.CREATE_ONE, payload)

    return formatItemResponse(res)
  },
  async updateOne(payload) {
    const res = await axios.put(URLs.UPDATE_ONE, payload)

    return formatItemResponse(res)
  },
  async deleteOne({ id }) {
    const res = await axios.delete(`${URLs.DELETE_ONE}${id}`)

    return formatItemResponse(res)
  }
}
