import axios from 'axios'

import { formatListResponse } from '@/utils/api'

const BASE_URL = `/api/member-network`
const URLs = new Proxy(
  {
    FETCH_TOP_STATS: `/get-top-stats/`,
    FETCH_RECENT_HIGHLIGHTS: `/get-recent-highlights/`,
    FETCH_SATURATION_MAPS: `/get-saturation-maps/`,
    FETCH_DASHBOARD_MAPS: `/get-dashboard-maps`,
    FETCH_DISCIPLINES_DATA: `/get-disciplines-data/`,
    FETCH_ALL_COUNTRY_GROUPS: `/get-all-country-groups/`,
    FETCH_DISTRIBUTED_TEAM_ASSIGNMENTS: `/get-distributed-team-assignments`,
    FETCH_DISTRIBUTED_TEAM: '/get-distributed-team/',

    EXPORT_TOP_STATS: '/export-top-stats',
    EXPORT_RANGE_STATS: '/export-range-stats'
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchTopStats({ groupCodeEnumName }) {
    const res = await axios.get(`${URLs.FETCH_TOP_STATS}${groupCodeEnumName}`)
    const { content, isSuccess, error } = res

    return { content, isSuccess, error }
  },
  async fetchRecentHighlights({ distributedTeamAssignmentId }) {
    const res = await axios.get(
      `${URLs.FETCH_RECENT_HIGHLIGHTS}${distributedTeamAssignmentId}`
    )
    const { content, isSuccess, error } = res

    return { content, isSuccess, error }
  },
  async fetchSaturationMaps({ selectedAttributes }) {
    const res = await axios.post(URLs.FETCH_SATURATION_MAPS, selectedAttributes)

    const { content, isSuccess, error } = res

    return { content, isSuccess, error }
  },
  async fetchDashboardMaps(payload) {
    const res = await axios.post(URLs.FETCH_DASHBOARD_MAPS, payload)

    const { content, isSuccess, error } = res

    return { content, isSuccess, error }
  },
  async fetchDisciplinesData({ groupCodeEnumName, attributeCategoryId }) {
    const res = await axios.get(
      `${URLs.FETCH_DISCIPLINES_DATA}${groupCodeEnumName}/${attributeCategoryId}`
    )
    const { content, isSuccess, error } = res

    return { content, isSuccess, error }
  },
  async fetchAllCountryGroups() {
    const res = await axios.get(URLs.FETCH_ALL_COUNTRY_GROUPS)
    const { content, isSuccess, error } = res

    return { content, isSuccess, error }
  },
  async fetchDistributedTeamAssignments() {
    const res = await axios.get(URLs.FETCH_DISTRIBUTED_TEAM_ASSIGNMENTS)

    return formatListResponse(res)
  },
  async fetchDistributedTeam({ distributedTeamAssignmentId }) {
    const res = await axios.get(
      `${URLs.FETCH_DISTRIBUTED_TEAM}${distributedTeamAssignmentId}`
    )

    const { content, isSuccess, error } = res

    return { content, isSuccess, error }
  },
  async exportTopStats(payload) {
    const res = await axios.post(URLs.EXPORT_TOP_STATS, payload, {
      responseType: 'blob'
    })

    return res
  },
  async exportRangeStats(payload) {
    const res = await axios.post(URLs.EXPORT_RANGE_STATS, payload, {
      responseType: 'blob'
    })

    return res
  }
}
