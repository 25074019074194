<template>
  <div class="h-full p-4 gap-16 flex flex-col bg-white">
    <!-- <div>{{ tenants }}</div> -->

    <!-- <PrincipalPayouts v-if="false" :principal-id="8084" /> -->

    <div class="w-full">
      <!-- <TheBudget v-if="false" :assignment-id="48" /> -->
    </div>
  </div>
</template>

<script>
// import { mapState } from 'vuex'

// import * as services from '@/services'

// import TheBudget from '@/components/Admin/JobManager/Budget/TheBudget.vue'
// import PrincipalPayouts from '@/components/Tenant/Principal/PrincipalPayouts/PrincipalPayouts.vue'

export default {
  name: 'PublicTestPage',

  // components: { TheBudget, PrincipalPayouts },

  data: () => ({
    // form: {
    //   name: ''
    // }
    // hello: []
  }),

  computed: {
    // ...mapState('tenants', ['tenants'])
  },

  methods: {}
}
</script>
