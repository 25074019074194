import axios from 'axios'

import { formatResponse, formatListResponse } from '@/utils/api'

const BASE_URL = `/api/featured-artist`
const URLs = new Proxy(
  {
    FETCH_ALL: `/get-all`,
    FETCH_ALL_ARCHIVED: `/get-all-archived`,
    FETCH_ALL_CURRENT: `/get-current`,

    FETCH_ALL_IMAGES: '/get-all-images/',
    FETCH_ALL_BY_USER: '/get-by-user/',

    FETCH_ONE_BY_ID: `/get-by-id/`,
    FETCH_ONE_COUNTRY_BY_ID: `/get-country-by-id/`,

    UPDATE_ONE: '/update',

    CREATE_ONE_PORTFOLIO_ITEM: '/create-portfolio-item',
    UPDATE_ONE_PORTFOLIO_ITEM: '/update-portfolio-item',
    DELETE_ONE_PORTFOLIO_ITEM: '/delete-portfolio-item/'
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchAll() {
    const res = await axios.get(URLs.FETCH_ALL)

    return formatListResponse(res)
  },
  async fetchAllArchived() {
    const res = await axios.get(URLs.FETCH_ALL_ARCHIVED)

    return formatListResponse(res)
  },
  async fetchAllCurrent() {
    const res = await axios.get(URLs.FETCH_ALL_CURRENT)

    return formatListResponse(res)
  },

  async fetchAllImagesById({ id }) {
    const res = await axios.get(`${URLs.FETCH_ALL_IMAGES}${id}`)

    return formatListResponse(res)
  },
  async fetchAllByUser({ id }) {
    const res = await axios.get(`${URLs.FETCH_ALL_BY_USER}${id}`)

    return formatResponse(res)
  },

  async fetchOneById({ id }) {
    const res = await axios.get(`${URLs.FETCH_ONE_BY_ID}${id}`)

    return formatResponse(res)
  },
  async fetchOneCountryById({ id }) {
    const res = await axios.get(`${URLs.FETCH_ONE_COUNTRY_BY_ID}${id}`)

    return formatResponse(res)
  },

  async updateOne(payload) {
    const res = await axios.put(URLs.UPDATE_ONE, payload)

    return formatResponse(res)
  },

  async createOnePortfolioItem(payload) {
    const res = await axios.post(URLs.CREATE_ONE_PORTFOLIO_ITEM, payload)

    return formatResponse(res)
  },
  async updateOnePortfolioItem(payload) {
    const res = await axios.post(URLs.UPDATE_ONE_PORTFOLIO_ITEM, payload)

    return formatResponse(res)
  },
  async deleteOnePortfolioItem({ id }) {
    const res = await axios.delete(`${URLs.DELETE_ONE_PORTFOLIO_ITEM}${id}`)

    return formatResponse(res)
  }
}
