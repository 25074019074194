import multiguard from 'vue-router-multiguard'

import * as notify from '@/utils/notify'

import store from '@/store'
import * as themeChanger from '@/utils/theme-changer'

const redirectToHome = next => {
  const isUserRole = store?.getters['user/isUserRole']

  return isUserRole?.anyAdmin
    ? next({ name: 'admin.home' })
    : next({ name: 't.home' })
}

const defineTenantsList = () => {
  const list = store.state.tenants.tenants.list

  return new Promise(resolve => {
    if (list !== null) resolve(list)

    store.watch(() => store.state.tenants.tenants.list, resolve)
  })
}

const defineUserTenantsList = () => {
  const list = store.state.user?.user?.tenants

  return new Promise(resolve => {
    if (list !== null) resolve(list)

    store.watch(() => store.state.user?.user?.tenants, resolve)
  })
}

const defineIsTenantExistsInList = (list, tenantMarker) => {
  return list?.map(t => t.marker).includes(tenantMarker)
}

const defineIsAuthorized = () => {
  const isAuthorized = store.getters['auth/isAuthorized']

  return new Promise(resolve => {
    if (isAuthorized !== null) resolve(isAuthorized)

    const timer = setTimeout(() => resolve(false), 30000)

    store.watch(
      () => store.getters['auth/isAuthorized'],
      value => {
        clearTimeout(timer)
        resolve(value)
      }
    )
  })
}

// const defineIsPrincipal = () => {
//   const isPrincipal = store.state.user.user

//   return new Promise(resolve => {
//     if (isPrincipal !== null) resolve(isPrincipal)

//     const timer = setTimeout(() => resolve(false), 10000)

//     store.watch(
//       () => store.state.user.user,
//       value => {
//         clearTimeout(timer)
//         resolve(value)
//       }
//     )
//   })
// }

const defineIsAllRequiredContractsSigned = () => {
  const isSigned =
    store.getters['principal/contracts/defineIsAllRequiredContractsSigned']()

  return new Promise(resolve => {
    if (isSigned !== null) resolve(isSigned)

    const timer = setTimeout(() => resolve(false), 5000)

    store.watch(
      () =>
        store.getters[
          'principal/contracts/defineIsAllRequiredContractsSigned'
        ](),
      value => {
        clearTimeout(timer)
        resolve(value)
      }
    )
  })
}

export const verifyGlobalTenant = async (to, from, next) => {
  const list = await defineTenantsList()

  const { tenantMarker } = to.params

  if (!tenantMarker && from.params.tenantMarker) {
    return next({
      ...to,
      params: { ...(to.params || {}), tenantMarker: from.params.tenantMarker }
    })
  }

  const isValid = defineIsTenantExistsInList(list, tenantMarker)

  if (isValid) return next()

  const isAuthorized = await defineIsAuthorized()

  if (isAuthorized) {
    const isToPath = Boolean(to.name)
    const toQuery = isToPath ? { toName: to.name, ...(to.params || []) } : null

    next({ name: 'select-tenant', query: toQuery })
  } else {
    next({ name: 'public.home' })
  }
}

export const setDefaultTheme = async (to, from, next) => {
  themeChanger.setTheme()

  next()
}

export const setTenantTheme = async (to, from, next) => {
  const { tenantMarker } = to.params

  const getTenantByMarker = store.getters['tenants/getTenantByMarker']

  const tenant = getTenantByMarker(tenantMarker)

  themeChanger.setTheme(tenant)

  next()
}

export const verifyPrincipalTenant = async (to, from, next) => {
  const list = await defineTenantsList()
  const userTenantList = await defineUserTenantsList()

  const { tenantMarker } = to.params

  const isTenantFromPath = Boolean(tenantMarker)
  const isTenantFromPathValid = defineIsTenantExistsInList(
    userTenantList,
    tenantMarker
  )

  if (isTenantFromPath && isTenantFromPathValid) {
    const tenant = store.getters['tenants/getTenantByMarker'](tenantMarker)

    store.commit('tenants/SET_SELECTED', tenant, { root: true })
    return next()
  }

  const selectedTenant = store.state.tenants.tenants.selected
  const isTenantFromStore = Boolean(selectedTenant)
  const isTenantFromStoreValid = defineIsTenantExistsInList(
    list,
    selectedTenant?.marker
  )

  if (isTenantFromStore && isTenantFromStoreValid) {
    return next({
      name: to.name,
      params: { ...(to.params || {}), tenantMarker: selectedTenant.marker }
    })
  }

  const isToPath = Boolean(to.name)
  const toQuery = isToPath ? { toName: to.name, ...(to.params || []) } : null

  next({ name: 'select-tenant', query: toQuery })
}

export const onlyIsPreAuthorizedOrAuthorized = async (to, from, next) => {
  if (store.state.auth.isPreAuthorized) return next()

  const isAuthorized = await defineIsAuthorized()

  if (isAuthorized) return next()

  return next({
    name: 't.auth.login',
    params: { tenantMarker: to.params.tenantMarker }
  })
}

export const onlyNotAuthorized = async (to, from, next) => {
  const isAuthorized = await defineIsAuthorized()

  if (!isAuthorized) return next()

  return redirectToHome(next)
}

export const onlyAuthorized = async (to, from, next) => {
  const isAuthorized = await defineIsAuthorized()

  if (isAuthorized) return next()

  store.dispatch('auth/LOGOUT', null, { root: true })
  next({ name: 't.auth.login' })
}

export const onlyNotAllRequiredContractsSigned = async (to, from, next) => {
  const isAllRequiredContractsSigned =
    await defineIsAllRequiredContractsSigned()

  if (!isAllRequiredContractsSigned) return next()

  return redirectToHome(next)
}

export const onlyNotCompletedProfile = (to, from, next) => {
  const isHub = store.getters['user/isHub']
  const isRequiredFieldsFilled = store.getters['user/isRequiredFieldsFilled']

  if (isHub) {
    return isRequiredFieldsFilled ? redirectToHome(next) : next()
  } else {
    return redirectToHome(next)
  }
}

export const onlyAdmin = (to, from, next) => {
  const isUserRole = store.getters['user/isUserRole']

  if (isUserRole?.anyAdmin) {
    const user = store.state.user.user
    const tenants = store.state.admin.tenants.tenants
    const isAdminTenantsSelectedSome = tenants.selected?.length > 0

    const selectedTenants = isAdminTenantsSelectedSome
      ? tenants.selected
      : user.tenants

    store.commit('tenants/SET_SELECTED', null, { root: true })
    store.commit('admin/tenants/SET_SELECTED', selectedTenants, {
      root: true
    })
  }

  isUserRole?.anyAdmin ? next() : next(false)
}
export const onlySuperAdmin = (to, from, next) => {
  const isUserRole = store.getters['user/isUserRole']

  isUserRole.superAdmin ? next() : next(false)
}
export const onlyMember = (to, from, next) => {
  const isMember = store.getters['user/isMember']

  isMember ? next() : next(false)
}
export const onlyHub = (to, from, next) => {
  const isHub = store.getters['user/isHub']

  isHub ? next() : next(false)
}

export const onlyAccountComplete = (to, from, next) => {
  const isAccountIncomplete = store.getters['user/isAccountIncomplete']
  const isAccountComplete = !isAccountIncomplete

  isAccountComplete ? next() : next(false)
}

export const onlyAccountIncomplete = (to, from, next) => {
  const isAccountIncomplete = store.getters['user/isAccountIncomplete']

  isAccountIncomplete ? next() : next({ name: 't.home' })
}

export const principalStatusCheck = async (to, from, next) => {
  const isHub = store.getters['user/isHub']
  const isAllRequiredContractsSigned =
    await defineIsAllRequiredContractsSigned()
  const isRegistrationIncomplete =
    store.getters['user/isRegistrationIncomplete']

  if (isHub) {
    if (isRegistrationIncomplete) {
      const name = 't.hub.complete-registration'
      return to.name === name ? next() : next({ name, params: to.params })
    }
  } else {
    if (isRegistrationIncomplete) {
      const name = 't.member.complete-registration'
      return to.name === name ? next() : next({ name, params: to.params })
    }
  }

  if (!isAllRequiredContractsSigned) {
    const name = 't.sign-required-contracts'
    return to.name === name ? next() : next({ name, params: to.params })
  }

  const isUserStatus = store.getters['user/isUserStatus']

  if (!isUserStatus.active) {
    const name = 't.principal-status-info'
    return to.name === name ? next() : next({ name, params: to.params })
  }

  const isRequiredFieldsFilled = store.getters['user/isRequiredFieldsFilled']

  if (isHub && !isRequiredFieldsFilled) {
    const name = 't.hub.complete-profile'
    return to.name === name ? next() : next({ name, params: to.params })
  }

  next()
}

export const onlyMultiTenantUser = (to, from, next) => {
  const user = store.state.user.user
  const isMultiTenantUser = user.roleNames?.includes('multitenantadmin')

  const singleTenant = user.tenants[0]
  const tenantMarker = singleTenant?.marker

  isMultiTenantUser
    ? next()
    : next({ name: 't.home', params: { tenantMarker } })
}

export const onlyNoTenant = (to, from, next) => {
  const { tenantMarker } = to.params

  const tenantFromPath = tenantMarker
  const principalTenant = store.state.tenants.tenants.selected
  const adminTenants = store.state.admin.tenants.tenants.selected

  const isAnyTenant = tenantFromPath || principalTenant || adminTenants[0]

  if (!isAnyTenant) return next()

  return redirectToHome(next)
}

export const onlyTenants = (to, from, next, allowedTenantMarkerList) => {
  const { tenantMarker } = to.params

  const isAllowedTenant = allowedTenantMarkerList.includes(tenantMarker)

  return isAllowedTenant ? next() : next({ name: 't.home' })
}

export const mfaConfigPage = async (to, from, next) => {
  const user = store.state.user.user

  const isPreAuthorized = store.state.auth.isPreAuthorized

  if (isPreAuthorized && !user.twoFactorAuthenticationOptionId) return next()

  const isAuthorized = await defineIsAuthorized()

  if (isAuthorized) return next()

  return next({
    name: 't.auth.login',
    params: { tenantMarker: to.params.tenantMarker }
  })
}

export const defineIsProjectsAllowed = async (to, from, next) => {
  const principalTenant = store.state.tenants.tenants.selected
  const rules = JSON.parse(principalTenant?.rulesJson || '{}')
  const isProjectsAllowed = !rules?.userAccessRules?.isProjectsHidden

  if (!isProjectsAllowed) {
    notify.info({
      title: 'This feature is currently unavailable for your company.'
    })
  }

  return isProjectsAllowed ? next() : next({ name: 't.home' })
}

export const multiple = multiguard
