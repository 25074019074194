import * as notify from '@/utils/notify'
import * as services from '@/services'

export default {
  namespaced: true,

  state: {
    files: {
      list: [],
      isLoading: false
    },

    categories: {
      list: [],
      isLoading: false
    }
  },

  actions: {
    async FETCH_ALL_BY_PRINCIPAL({ rootState, commit }, principal) {
      commit('SET_FILES_IS_LOADING', true)

      principal = principal || rootState.user.user

      const payload = {
        principalId: principal.id,
        userTypeCode: principal.userTypeCode
      }

      try {
        const { list } =
          await services.principalFiles.fetchAllByPrincipal(payload)

        await commit('SET_FILES_LIST', list)
      } catch (error) {
        notify.error({ text: error })
      } finally {
        commit('SET_FILES_IS_LOADING', false)
      }
    },

    async FETCH_ALL_BY_PRINCIPAL_AND_CATEGORY(
      { rootState, commit },
      { principal, categoryId }
    ) {
      commit('SET_FILES_IS_LOADING', true)

      principal = principal || rootState.user.user

      const payload = {
        principalId: principal.id,
        userTypeCode: principal.userTypeCode,
        categoryId
      }

      try {
        const { list } =
          await services.principalFiles.fetchAllByPrincipalAndCategory(payload)

        await commit('SET_FILES_LIST', list || [])
      } catch (error) {
        notify.error({ text: error })
      } finally {
        commit('SET_FILES_IS_LOADING', false)
      }
    },

    // async ADD_FOR_PRINCIPAL(_, payload) {
    //   return services.principalFiles.addMultiple(payload)
    // }

    async ADD_ONE_INVOICE({ commit, dispatch }, { principal, payload }) {
      commit('SET_FILES_IS_LOADING', true)

      const result = await services.principalFiles.addOneInvoice(payload)
      const { isSuccess } = result

      if (isSuccess) {
        await dispatch('FETCH_ALL_BY_PRINCIPAL', principal)
      }

      commit('SET_FILES_IS_LOADING', false)

      return result
    },

    async ADD_ONE({ commit, dispatch }, { principal, payload }) {
      commit('SET_FILES_IS_LOADING', true)

      const result = await services.principalFiles.addOne(payload)
      const { isSuccess } = result

      if (isSuccess) {
        await dispatch('FETCH_ALL_BY_PRINCIPAL', principal)
      }

      commit('SET_FILES_IS_LOADING', false)

      return result
    },

    async UPDATE_ONE_WITH_FILE({ commit, dispatch }, { principal, payload }) {
      commit('SET_FILES_IS_LOADING', true)

      const result = await services.principalFiles.updateOneWithFile(payload)
      const { isSuccess } = result

      if (isSuccess) {
        await dispatch('FETCH_ALL_BY_PRINCIPAL', principal)
      }

      commit('SET_FILES_IS_LOADING', false)

      return result
    },

    async DELETE_ONE({ commit, dispatch }, { principal, id }) {
      commit('SET_FILES_IS_LOADING', true)

      const result = await services.principalFiles.deleteOne({
        principal,
        id
      })

      const { isSuccess } = result

      if (isSuccess) {
        await dispatch('FETCH_ALL_BY_PRINCIPAL', principal)
      }

      commit('SET_FILES_IS_LOADING', false)

      return result
    },

    async FETCH_ALL_CATEGORIES({ commit }) {
      commit('SET_CATEGORIES_IS_LOADING', true)

      try {
        const { list } = await services.principalFiles.fetchAllCategories()

        await commit('SET_CATEGORIES_LIST', list)
      } catch (error) {
        notify.error({ text: error })
      } finally {
        commit('SET_CATEGORIES_IS_LOADING', false)
      }
    }
  },

  mutations: {
    SET_FILES_LIST(state, list) {
      state.files.list = list || []
    },
    SET_FILES_IS_LOADING(state, isLoading) {
      state.files.isLoading = isLoading
    },

    SET_CATEGORIES_LIST(state, list) {
      state.categories.list = list
    },
    SET_CATEGORIES_IS_LOADING(state, isLoading) {
      state.categories.isLoading = isLoading
    }
  }
}
